<template>
  <v-container fluid>
    <v-card>
      <v-card-title primary-title>
        <v-text-field
          v-model="search"
          v-bind="bind.search"
          v-on="on.search"
        ></v-text-field>

        <v-divider v-bind="bind.divider"></v-divider>

        <v-spacer></v-spacer>

        <v-btn v-bind="bind.refresh" v-on="on.refresh">
          <v-icon>{{ icons.refresh }}</v-icon>
        </v-btn>

        <v-btn v-bind="bind.new" v-on="on.new">
          {{ labels.new }}
        </v-btn>
      </v-card-title>

      <v-data-table v-bind="bind.table" :search="search">
        <template #item.status="{ item }">
          <v-switch
            v-bind="bind.activate(item)"
            v-on="on.activate(item)"
          ></v-switch>
        </template>

        <template #item.actions="{ item }">
          <v-btn
            color="primary"
            fab
            x-small
            text
            @click="(selected = item), (dialog = true)"
          >
            <v-icon>{{ icons.edit }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <Modal v-model="dialog" v-bind="bind.form" v-on="on.form" />
  </v-container>
</template>
<script>
import { PageMixin } from "@/Mixins/SetPageMixin.js";

import Modal from "@/components/Modal/ModalProduct/Modal-planes";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CRUDPlansPages",
  mixins: [PageMixin],
  components: {
    Modal,
  },
  data: () => ({
    headers: [
      { text: "Plan Descripción", value: "plan_descripcion", align: "left" },
      { text: "Código", value: "codigo", align: "center" },
      { text: "Deducible", value: "deducible", align: "center" },
      { text: "Ramo", value: "ramo_descripcion", align: "left" },
      { text: "Estado", value: "status", align: "left", sortable: false },
      { text: "", value: "actions", align: "right", sortable: false },
    ],
    plan: [],
  }),
  computed: {
    ...mapGetters("Plans", ["getItems"]),

    items() {
      return this.getItems;
    },
  },
  methods: {
    ...mapActions("Plans", ["GET", "ACTIVATE"]),

    setup() {
      this.loading = true;
      this.GET()
        .catch((error) => {
          this.snackbar(error.result?.message || error.info);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    saveItem() {
      this.closeForm();
      this.setup();
    },

    activateItem(e, item) {
      const itemId = item.plan;
      item.loading = true;
      this.ACTIVATE({ deactivate: !e, id: itemId })
        .then((response) => {
          this.snackbar(response.info, "success");
          this.setup();
        })
        .catch((error) => {
          this.snackbar(error.result?.message || error.info);
        })
        .finally(() => {
          item.loading = false;
        });
    },
  },
};
</script>
