<template>
  <v-dialog
    :value="value"
    max-width="600"
    @click:outside="close"
    :persistent="loadingForm"
  >
    <v-card>
      <v-card-title primary-title>
        {{ bind.title }} <v-spacer></v-spacer>
        <v-btn v-bind="bind.close" v-on="on.close" :disabled="loadingForm">
          <v-icon>{{ icons.close }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form :ref="formRef">
          <v-row multi-line>
            <v-col cols="12" md="7" v-if="!ramoId">
              <v-autocomplete
                item-text="ramo_descripcion"
                item-value="_id"
                :items="ramos"
                label="Ramo"
                :loading="loadingRamo"
                v-model="form.ramo"
                dense
                :rules="[(v) => !!v || 'Debe seleccionar un ramo.']"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                v-model="form.codigo"
                label="Código"
                dense
                :rules="[(v) => !!v || 'El campo Código es requerido.']"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="7">
              <v-text-field
                v-model="form.plan_descripcion"
                name="tipo"
                label="Descripción del plan"
                placeholder="Escriba descripción del plan"
                dense
                :rules="[(v) => !!v || 'El campo Descripción es requerido.']"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                v-model="form.deducible"
                name="Deducible"
                label="N° Deducible"
                placeholder="Deducible"
                dense
                :rules="[(v) => !!v || 'El campo nro deducible es requerido.']"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.submit" v-on="on.submit" :loading="loadingForm">
          {{ labels.submit }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import FormMixin from "@/Mixins/SetFormMixin.js";

export default {
  name: "PlansFormModal",
  mixins: [FormMixin],
  props: {
    ramoId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      defaultTitle: "Formulario de Planes",
      form: {
        codigo: "",
        deducible: "",
        plan_descripcion: "",
        ramo: "",
      },
      loadingForm: false,
      loadingRamo: false,
      ramos: [],
    };
  },
  created() {
    this.setup();
  },
  watch: {
    ramoId: function (newVal) {
      this.form.ramo = newVal;
    },
  },
  methods: {
    ...mapActions("NewProduct", ["CrearPlan", "UpdatePlan", "getRamos"]),

    submit() {
      if (this.formComponent.validate()) {
        this.loadingForm = true;
        const payload = { ...this.form };
        let METHOD = this.CrearPlan;
        if (this.formData) {
          METHOD = this.UpdatePlan;
          payload.id = this.formData.plan;
        }

        METHOD(payload)
          .then((response) => {
            this.snackbar(response.info, "success");
            this.$emit("submit");
          })
          .catch((error) => {
            this.snackbar(error.result?.message || error.info);
          })
          .finally(() => {
            this.loadingForm = false;
          });
      }
    },

    setup() {
      this.loadingRamo = true;
      if (this.ramoId) {
        this.form.ramo = this.ramoId;
      }
      this.getRamos()
        .then((response) => {
          this.ramos = response;
        })
        .catch((e) => {
          this.snackbar(e.info);
        })
        .finally(() => {
          this.loadingRamo = false;
        });
    },

    snackbar(message = "", type = "error") {
      this.$store.commit("setSnackbar", {
        active: true,
        color: type,
        top: true,
        right: true,
        text: message,
      });
    },
  },
};
</script>
